import React from "react";
import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";

import NotFound from "./not-found";

const Homepage = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./homepage"),
  loading: () => null,
  modules: ["homepage"]
});

const LivingShort = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./living/short"),
  loading: () => null,
  modules: ["living-short"]
});

const LivingFull = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./living/full"),
  loading: () => null,
  modules: ["living-full"]
});

const ProgramShort = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./program/short"),
  loading: () => null,
  modules: ["program-short"]
});

const ProgramFull = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./program/full"),
  loading: () => null,
  modules: ["program-full"]
});

const UniversityShort = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./university/short"),
  loading: () => null,
  modules: ["university-short"]
});

const UniversityFull = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./university/full"),
  loading: () => null,
  modules: ["university-full"]
});

const BlogShort = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./blog/short"),
  loading: () => null,
  modules: ["blog-short"]
});

const BlogFull = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./blog/full"),
  loading: () => null,
  modules: ["blog-full"]
});

const Agents = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./agents"),
  loading: () => null,
  modules: ["agents"]
});

const Contacts = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./contacts"),
  loading: () => null,
  modules: ["contacts"]
});

const DopUslugy = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./dopUslugy"),
  loading: () => null,
  modules: ["dopUslugy"]
});

const Faq = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./faq"),
  loading: () => null,
  modules: ["faq"]
});

const Partners = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./partners"),
  loading: () => null,
  modules: ["partners"]
});


const UserArea = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./user"),
  loading: () => null,
  modules: ["user"]
});



export default () => (
  <Switch>
    <Route exact path="/" component={Homepage} />
    <Route exact path="/living" component={LivingShort} />
    <Route exact path="/living/:urlLiving" component={LivingFull} />

    <Route exact path="/program" component={ProgramShort} />
    <Route exact path="/program/:url" component={ProgramFull} />

    <Route exact path="/university" component={UniversityShort} />
    <Route exact path="/university/:url" component={UniversityFull} />

    <Route exact path="/blog" component={BlogShort} />
    <Route exact path="/blog/:url" component={BlogFull} />

    <Route exact path="/agents" component={Agents} />
    <Route exact path="/contacts" component={Contacts} />
    <Route exact path="/services" component={DopUslugy} />
    <Route exact path="/faq" component={Faq} />
    <Route exact path="/partners" component={Partners} />

    <Route path="/user" component={UserArea} />

    <Route component={NotFound} />
  </Switch>
);
