import React, {Component} from 'react';
import './style.scss'

export default class Preload extends Component {
  render() {
    return(
      <div className="preloader">
        <div uk-spinner="ratio: 3"></div>
      </div>
    )
  }
}
