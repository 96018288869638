import React, {Fragment} from 'react'

const Login = ({error, handleInput, email, password, login}) => {

  return(
    <Fragment>
      <div id="modal-login" className="modal uk-modal-full" uk-modal="">
        <div className="uk-modal-dialog uk-height-1-1 uk-flex uk-flex0-middle">
         	<button className="uk-modal-close-full uk-close-large" type="button" uk-close=""></button>
          <div className="uk-width-1-1">
           	<div className="uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
              <div className="reg_wrap uk-width-1-1 uk-flex uk-flex-center uk-flex-wrap uk-margin-large-top">

                {error ? <div className="uk-alert-danger uk-text-center uk-position-relative" style={{top: '0'}} uk-alert=""><p>Неверный логин или пароль</p></div> : ''}

               	<form className="input_wrap" onSubmit={e => login(e)}>
  							 	<div className="uk-margin">
  					        <label className="uk-form-label" htmlFor="form-stacked-text">Ваш email*</label>
  					        <div className="uk-form-controls">
  				            <input className={error ? 'uk-form-danger uk-input' : 'uk-input'} name="email" type="text" placeholder="Email" onChange={e => handleInput(e)} tabIndex="1" value={email} />
  					        </div>
  						    </div>

                  <div className="uk-margin">
  					        <label className="uk-form-label" htmlFor="form-stacked-text">Ваш пароль*</label>
  					        <div className="uk-form-controls">
  				            <input className={error ? 'uk-form-danger uk-input' : 'uk-input'} name="password" type="password" tabIndex="2" onChange={e => handleInput(e)} value={password} />
  					        </div>
  						    </div>

  								<button className="uk-button uk-width-1-1 uk-button-default" tabIndex="13">Отправить</button>
                  <p className="uk-text-center">* для получения доступа нужно <a href="#modal-registration" uk-toggle="">заполнить анкету</a></p>
                </form>
              </div>
           	</div>
         	</div>
        </div>
      </div>
    </Fragment>
  )
}


export default Login
