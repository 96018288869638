import React, { Component, Fragment } from "react";
import sanityClient from "../lib/sanity.js";
import Preloader from './components/preload'
import Gdpr from './components/gdpr'

// import logo from "./assets/logo.svg";
import logo from "./assets/logo_ru.svg";
import Visa from "./assets/Visa.png";
import Maestro from "./assets/Maestro.png";
import Mastercard from "./assets/Mastercard.png";
import facebook from "./assets/facebook-f-brands.svg";
import vk from "./assets/vk-brands.svg";
import odnoklassniki from "./assets/odnoklassniki-brands.svg";
import twitter from "./assets/twitter-brands.svg";
import instagram from "./assets/instagram-brands.svg";

const query = `{
  'menu': *[_type == "menu" && location == 'menu_footer_1' || location == 'menu_footer_2' || location == 'menu_footer_3'] {
    _id,
    title,
    items
  }[0...3],
  'soc': *[_type == "global"] {
    socLinks
  }[0]
}
`;

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soc: {},
      menu: {}
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    sanityClient
      .fetch(query)
      .then(this.getData)
      .catch(err => console.log(err));
  }

  getData(data) {
    this.setState({
      soc: data.soc,
      menu: data.menu
    });
  }

  render() {
    var { socLinks } = this.state.soc;
    var menus = this.state.menu;
    if (menus && socLinks) {
      return (
        <Fragment>
          <footer>
            <div className="footer-top">
              <div className="uk-container ">
                <div className="uk-grid uk-child-width-1-4@m uk-child-width-1-2@s uk-child-width-1-1">
                  <div>
                    <div className="footer_contact">
                      <img src={logo} alt="" />
                      <p>Мы в соцсетях:</p>
                      <ul>
                        {socLinks.facebook ? (
                          <li>
                            <a href={socLinks.facebook}>
                              <img src={facebook} alt="facebook" />
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {socLinks.vkontakte ? (
                          <li>
                            <a href={socLinks.vkontakte}>
                              <img src={vk} alt="vk" />
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {socLinks.odnoklassniki ? (
                          <li>
                            <a href={socLinks.odnoklassniki}>
                              <img src={odnoklassniki} alt="odnoklassniki" />
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {socLinks.twitter ? (
                          <li>
                            <a href={socLinks.twitter}>
                              <img src={twitter} alt="twitter" />
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {socLinks.instagram ? (
                          <li>
                            <a href={socLinks.instagram}>
                              <img src={instagram} alt="instagram" />
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                  {menus.map((item, index) => (
                    <div key={index}>
                      <ul>
                        <li className="footer-head">{item.title}</li>
                        {item.items.map((menu, indexMenu) => (
                          <li key={indexMenu}>
                            <a href={menu.menuUrl}>{menu.title}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="uk-container ">
                <div className="uk-grid uk-child-width-1-2@s uk-child-width-1-1">
                  <div>
                    <div className="copyright">© {new Date().getFullYear()} Все права защищены.</div>
                  </div>
                  <div>
                    <div className="pay-method uk-text-right@s">
                      <ul>
                        <li>
                          <img src={Visa} alt="" />
                        </li>
                        <li>
                          <img src={Maestro} alt="" />
                        </li>
                        <li>
                          <img src={Mastercard} alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <Gdpr />
        </Fragment>
      );
    } else {
      return <Preloader />;
    }
  }
}
