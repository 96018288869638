import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie';
import './style.scss'

const Gdpr = () => {

  const [agree, setAgree] = useState(true)

  useEffect(() => {
    let cookie = localStorage.getItem('agree_gdpr')
    if(!cookie){
      setAgree(false)
    }
  }, [])

  const closeCookies = () => {
    localStorage.setItem('agree_gdpr', 'true')
    setAgree(true)
  }

  if(!agree){
    return(<div className="gdpr">
      <p>На нашем сайте используются файлы cookies. Оставаясь на сайте, Вы соглашаетесь с их использованием. <Link to="/blog/cookies">Подробнее</Link><button className="close-gdpr" type="button" onClick={() => closeCookies()} uk-close=""></button></p>
    </div>)
  }else{
    return <div></div>
  }
}

export default Gdpr
