import React, {Component} from 'react'
import axios from 'axios'
import Cookies from 'js-cookie';
import Preloader from '../../preload'

import country from '../../../data/country.json'
import month from '../../../data/month.json'

export default class Registration extends Component {

  state = {
    name: '',
    surname: '',
    birthday: '',
    birthyear: '',
    birthmonth: '',
    sex: '',
    country: '',
    city: '',
    email: '',
    password: '',
    phone: '',
    emptyInput: [],
    loadding: false
  }

  makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      error: false,
      emptyInput: []
    })
  }

  findEmpty = name => {
    let value = this.state.emptyInput.find(item => item === name);
    return value
  }

  onSubmit = e => {
    this.setState({loadding: true})
    let birthday = `${this.state.birthday}.${this.state.birthmonth}.${this.state.birthyear}`
    let password = 'scz_' + this.makeid(7);

    this.setState({
      birthday,
      password
    }, () => axios.post('/.netlify/functions/userCreate', this.state).then(res => {
      if(Array.isArray(res.data.error)){
        this.setState({
          error: 'empty',
          emptyInput: res.data.error
        })
      }else if(res.data.error === 'email'){
        this.setState({error: 'email'})
      }else{
        localStorage.setItem('userId', res.data.data._id);
        localStorage.setItem('user', JSON.stringify(res.data.data));
        localStorage.setItem('question', JSON.stringify(res.data.question));
        this.props.toggle('#modal-done', 'show');
        var mailData = {
          email: this.state.email,
          password: this.state.password
        }
        axios.post('/.netlify/functions/sendRegistration', mailData)
        axios.post('/.netlify/functions/sendAdminRegistration', res.data.data)
      }
      this.setState({loadding: false})
    }))
  }

  render() {
    return(
      <div id="modal-registration" className={`modal uk-modal-full ${this.props.visible ? 'uk-hidden' : ''}`} uk-modal="">
        <div className="uk-modal-dialog uk-height-1-1 uk-flex uk-flex-middle">
         	<button className="uk-modal-close-full uk-close-large" type="button" uk-close=""></button>
          <div className="uk-width-1-1">
           	<div className="uk-container">
             	<div className="uk-flex uk-flex-center uk-flex-middle">
                <div className="reg_wrap uk-width-1-1 uk-flex uk-flex-center uk-flex-wrap uk-margin-large-top">
                  {this.state.error === 'email' ? <div className="uk-alert-danger" uk-alert="animation: true; duration: 200;">
                    <a className="uk-alert-close" nohref="" uk-close="">{null}</a>
                    <p>Пользователь с таким емайлом уже существует. Ипользуйте другой или войдите. </p>
                  </div> : ''}
                  {this.state.error === 'empty' ? <div className="uk-alert-danger" uk-alert="animation: true; duration: 200;">
                    <a className="uk-alert-close" uk-close=""></a>
                    <p>Пожалуйста, заполните все поля. </p>
                  </div> : ''}
  								<h2 className="modal-head">Персональная информация</h2>
                 	<div className="input_wrap">
  								 	<div className="uk-margin">
  						        <label className="uk-form-label" htmlFor="form-stacked-text">Имя:</label>
  						        <div className="uk-form-controls">
  					            <input className={this.findEmpty('name') ? 'uk-form-danger uk-input' : 'uk-input'} name="name" type="text" placeholder="Иван" onChange={this.handleInput} tabIndex="1" value={this.state.name} />
  						        </div>
  							    </div>
  									<div className="uk-margin">
  								 		<label className="uk-form-label" htmlFor="form-stacked-text">Дата рождения</label>
                        <div className="tm-date-input uk-form-controls uk-grid uk-grid-collapse" uk-grid="">
  											<div className="uk-width-1-4"><input className="uk-input" type="text" placeholder="04" name="birthday" onChange={this.handleInput} tabIndex="3" value={this.state.birthday} /></div>

  											<div className="uk-width-1-2" uk-form-custom="target: > * > span:first-child">
  						            <select name="birthmonth" onChange={this.handleInput} value={this.state.birthmonth} tabIndex="4">
  					                <option value="">Выберите...</option>
  					                {Object.keys(month).map(item => <option key={item} value={item < 10 ? '0'+item : item}>{month[item]}</option>)}
  						            </select>
  						            <button className="tm-select" type="button" tabIndex="-1">
  					                <span></span>
  													<span uk-icon="icon: chevron-down"></span>
  						            </button>
  							        </div>
  											<div className="uk-width-1-4"><input className="uk-input" type="text" name="birthyear" onChange={this.handleInput} placeholder="1996" tabIndex="5" value={this.state.birthyear} /></div>
  										</div>
  								 	</div>
  									<div className="uk-margin">
  										<label className="uk-form-label" htmlFor="form-stacked-text">Страна</label>
  										<div className="uk-form-controls">
  											<div uk-form-custom="target: > * > span:first-child">
  						            <select name="country" onChange={this.handleInput} value={this.state.country} tabIndex="8">
  					                <option value="">Выберите...</option>
  					                {Object.values(country).map((item, index) => <option key={index} value={item}>{item}</option> )}
  						            </select>
  						            <button className="tm-select" type="button" tabIndex="-1">
  					                <span></span>
  													<span uk-icon="icon: chevron-down"></span>
  						            </button>
  							        </div>
  										</div>
  									</div>

  									<div className="uk-margin-large-bottom">
  							 			<label className="uk-form-label" htmlFor="form-stacked-text">E-mail</label>
  							 			<div className="uk-form-controls">
  							 				<input className={this.state.error || this.findEmpty('email') ? 'uk-form-danger uk-input' : 'uk-input'} type="email" name="email" onChange={this.handleInput} placeholder="example@email.com" tabIndex="10" value={this.state.email} />
  							 			</div>
  							 		</div>

  									<button className="uk-button uk-button-text uk-visible@s" tabIndex="12" onClick={() => this.props.toggle('#modal-registration', 'hide')}>Отмена</button>

                 	</div>
                 	<div className="input_wrap">
  									<div className="uk-margin">
  										<label className="uk-form-label" htmlFor="form-stacked-text">Фамилия:</label>
  										<div className="uk-form-controls">
  											<input className={this.findEmpty('surname') ? 'uk-form-danger uk-input' : 'uk-input'} type="text" name="surname" onChange={this.handleInput} placeholder="Иванов" tabIndex="2" value={this.state.surname} />
  										</div>
  									</div>
  									<div className="uk-margin">
  										<label className="uk-form-label" htmlFor="form-stacked-text">Пол</label>
  										<div className="tm-radio uk-form-controls uk-grid uk-grid-collapse uk-child-width-1-2" uk-grid="">
  											<div><input className="uk-radio" type="radio" id="radio_1" value="Мужской" checked={this.state.sex === 'Мужской'} tabIndex="6" onChange={this.handleInput} name="sex" /><label htmlFor="radio_1">Мужской</label></div>
  											<div><input className="uk-radio" type="radio" id="radio_2" value="Женский" checked={this.state.sex === 'Женский'} tabIndex="7" onChange={this.handleInput} name="sex" /><label htmlFor="radio_2">Женский</label></div>
  										</div>
  									</div>
  									<div className="uk-margin">
  								 		<label className="uk-form-label" htmlFor="form-stacked-text">Город</label>
  										<div className="uk-form-controls">
  											<input className={this.findEmpty('city') ? 'uk-form-danger uk-input' : 'uk-input'} type="text" name="city" onChange={this.handleInput} placeholder="Киев" tabIndex="9" value={this.state.city}/>
  										</div>
  									</div>
  									<div className="uk-margin-large-bottom">
  										<label className="uk-form-label" htmlFor="form-stacked-text">Телефон</label>
  										<div className="uk-form-controls">
  											<input className={this.findEmpty('phone') ? 'uk-form-danger uk-input' : 'uk-input'} type="text" name="phone" onChange={this.handleInput} placeholder="+7 777 777 777" tabIndex="11" value={this.state.phone}/>
  										</div>
  									</div>
                    <button className="uk-button uk-button-text uk-hidden@s" tabIndex="12" onClick={() => this.props.toggle('#modal-registration', 'hide')}>Отмена</button>
  									<button className="uk-button uk-button-default uk-align-right" onClick={this.onSubmit} tabIndex="13">Отправить</button>
  								</div>
                </div>
             	</div>
           	</div>
         	</div>
        </div>
        {this.state.loadding ? <div style={{position: 'fixed', top: '0', left: '0'}}><Preloader /></div> : ''}
      </div>
    )


  }
}
