// The basics
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import axios from 'axios'

import Header from "./header";
import Routes from "./routes";
import Footer from "./footer";

import "./app.css";
import "./scss/style.scss";

class App extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Routes />
        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(App);
