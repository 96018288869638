import React from 'react'
// import {Link} from 'react-router-dom'
import './style.scss'

const Done = ({visible, toggle}) => {

  return(
    <div id="modal-done" className={`modal uk-modal-full uk-modal`} uk-modal="">
      <div className="uk-modal-dialog uk-height-1-1 uk-flex uk-flex-middle">
       	<button className="uk-modal-close-full uk-close-large" type="button" onClick={(e) => toggle('#modal-done', 'hide')} uk-close=""></button>
        <div className="uk-flex uk-flex-center uk-flex-middle uk-width-1-1 uk-height-1-1">
          <div className="modal-done-wrap">
            <div className="big-circle-done"></div>
            <h2>Ваша заявка принята!</h2>
            <p>Мы отправили Вам письмо с паролем на указанный почтовый ящик.</p>
            <a href={`/user/personal-area`} className="button button_blue">Продолжить</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Done
