import { combineReducers } from 'redux';

import auth from './auth';
// import profile from './profile';
// import anketa from './anketa';

export default combineReducers({
  auth,
  // anketa
});
