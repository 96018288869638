import React from 'react'
import './style.scss'

const Done = ({label, id}) => {

  return(
    <div id={id} className="modal uk-modal-full uk-modal" uk-modal="">
      <div className="uk-modal-dialog uk-height-1-1 uk-flex uk-flex-middle">
       	<button className="uk-modal-close-full uk-close-large" type="button" uk-close=""></button>
        <div className="uk-flex uk-flex-center uk-flex-middle uk-width-1-1 uk-height-1-1">
          <div className="modal-done-wrap modal-call-done">
            <div className="big-circle-done"></div>
            <p>{label}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Done
