import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import sanityClient from "../lib/sanity.js";
import axios from 'axios'
import Cookies from 'js-cookie';

import Registration from './components/modals/registration'
import Done from './components/modals/reg-done'
import Login from './components/modals/login'
import Call from './components/modals/call'
import Password from './components/modals/password'
import Preloader from './components/preload'

// import logo from "./assets/logo.svg";
import logo from "./assets/logo_ru.svg";
import down from './assets/angle-down-light.svg'

const query = `{
  'menu' : *[_type == "menu" && location == 'menu_top'] {
    _id,
    title,
    items
  }[0...1],
  'contacts' : *[_type == "global"] {
    contacts
  }[0...1]
}`;

export default class Header extends React.Component {

  state = {
    contacts: {},
    menu: {},
    showLogin: false,
    showMobileMenu: false,
    email: '',
    password: '',
    error: false,
    loggin: false,
    name: '',
    id: '',
    loadding: false
  }

  componentDidMount() {
    sanityClient
      .fetch(query)
      .then(this.getData)
      .catch(err => console.log(err));

    let user = JSON.parse(localStorage.getItem('user'));
    if(user){
      this.setState({
        name: user.name + ' ' + user.surname,
        loggin: true,
        id: user._id,
        stepQuestionare: user.stepQuestionare
      })
    }
  }

  getData = data => {
    this.setState({
      contacts: data.contacts[0],
      menu: data.menu[0]
    });
  }

  toggleModal = (element, events) => {
    if(events === 'hide'){
      window.UIkit.modal(window.UIkit.util.find(element)).hide();
    }else if(events === 'show'){
      window.UIkit.modal(window.UIkit.util.find(element)).show();
    }
  }

  toggleLogin = () => {
    this.setState({
      showLogin: !this.state.showLogin
    })
  }

  toggleMobileMenu = () => {
    this.setState({
      showMobileMenu: !this.state.showMobileMenu
    })
  }

  hendleInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      error: false
    })
  }

  loggout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('question');
  }

  login = async e => {
    e.preventDefault()
    if(!this.state.email || !this.state.password){
      this.setState({error: true})
    }else{
      this.setState({loadding: true})
      var loginData = {
        email: this.state.email,
        password: this.state.password
      }
      await axios.post('/.netlify/functions/login', loginData)
        .then(res => {
          if(!res.data.error){
            localStorage.setItem('user', JSON.stringify(res.data.data));
            localStorage.setItem('userId', res.data.data._id);
            localStorage.setItem('question', JSON.stringify(res.data.question));
            window.location.href = '/user/personal-area'
          }
        }).catch(err => {
          this.setState({
            error: true,
            loadding: false
          })
        })
    }
  }

  render() {
    var url = window.location.pathname.split('/')[1]
    var menu = this.state.menu;
    var { contacts } = this.state.contacts;
    if (Object.keys(menu).length) {
      return (
        <Fragment>
          <header>
            <div className="header-top">
              <div className="uk-container uk-flex uk-flex-between uk-flex-wrap">
                <Link to="/" className="logo">
                  <img src={logo} alt="Logo" />
                </Link>

                <div className="uk-hidden@s">
                  <a href={!this.state.loggin ? "#modal-login" : "/user/personal-area"} uk-toggle={!this.state.loggin ? "" : false} className="userIconMobile">
                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="user" className="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"></path></svg>
                  </a>
                </div>

                <button className={`hamburger header-hamburger hamburger--arrowalt-r uk-hidden@s ${this.state.showMobileMenu ? 'is-active' : ''}`} onClick={this.toggleMobileMenu} type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>

                <nav className={`menu-top ${this.state.showMobileMenu ? 'mobile-mnu-is-active' : ''}`}>
                  <ul>
                    <li className="uk-hidden@s">Меню</li>
                    {menu.items.slice(0, 6).map((item, index) =>
                      <li key={index} className={'/'+url === item.menuUrl ? 'nav_active basic_nav' : 'basic_nav'}>
                        <Link to={item.menuUrl} onClick={() => this.setState({showMobileMenu: false})}><span>{item.title}</span></Link>
                      </li>
                    )}
                    <li style={this.state.loggin ? {paddingRight: '0'} : {}} className={url === 'faq' ? 'nav_active basic_nav' : ''}>
                      <Link className="uk-visible@s" to="/faq" aria-label="Ответы на вопросы" title="Ответы на вопросы" uk-tooltip="title: Ответы на вопросы; pos: bottom; offset: 20">
                        <span className="tm-icons tm-icon-ask" />
                      </Link>
                      <Link className="uk-hidden@s" to="/faq" onClick={() => this.setState({showMobileMenu: false})} title="Ответы на вопросы">
                        Ответы на вопросы
                      </Link>
                    </li>
                    {!this.state.loggin ? <li className="menu-call-action">
                      <a className="button button-accent" href="#modal-registration" uk-toggle="">Заполнить анкету</a>
                    </li> : ''}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="header_bottom uk-visible@s">
              <div className="uk-container ">
                <div className="uk-grid">
                  <div className="uk-width-2-3@s">
                    <a href={`tel:${contacts.phone}`} className="contact-info-top">
                      <span className="tm-icons tm-icon-phone" />
                      {contacts.phone}
                    </a>
                    <a href={`mailto:${contacts.email}`} className="contact-info-top">
                      <span className="tm-icons tm-icon-envelope" />
                      {contacts.email}
                    </a>
                  </div>
                  <div className="uk-text-right uk-width-1-3@s">
                    <div className="login-form">
                    {!this.state.loggin ? ([
                      <div className="uk-text-right" key="A">
                        <span className="tm-icons tm-icon-exit" />
                        <button className="button button-bare" onClick={this.toggleLogin}>Войти</button>
                      </div>,
                      <div className={`modal-login ${!this.state.showLogin ? 'uk-hidden' : ''}`} key="B">
                        <form id="login" action="" onSubmit={e => this.login(e)}>
                          {this.state.error ? <div className="uk-alert-danger uk-text-center uk-position-relative" style={{top: '0'}} uk-alert=""><p>Неверный логин или пароль</p></div> : ''}
                          <input className={this.state.error ? 'uk-form-danger' : ''} type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.hendleInput} />
                          <input className={this.state.error ? 'uk-form-danger' : ''} type="password" placeholder="Пароль" name="password" value={this.state.password} onChange={this.hendleInput} />
                          <p>* для получения доступа нужно <a href="#modal-registration" uk-toggle="">заполнить анкету</a></p>
                        </form>
                        <button type="submit" form="login" className="button button-blue" >Авторизоваться</button>
                        {this.state.loadding ? <div className="loadding"><div uk-spinner=""></div></div> : ''}
                      </div>
                    ]) : ([
                      <div className="uk-text-right loggin-user-button" key="A">
                        <button className="button button-bare">{this.state.name}</button>
                        <img src={down} alt="Down"/>
                      </div>,
                      <div className="drop-user" uk-dropdown="mode: hover; pos: bottom-right; offset: 20; duration: 200" key="B">
                        <ul>
                          <li><a href="/user/personal-area">Личный кабинет</a></li>
                          <li><a href={`/user/questionnaire/step-${this.state.stepQuestionare}`}>Моя анкета</a></li>
                          {/* <li><Link to="/">Оплата услуг</Link></li>*/}
                          <li><a nohref="" onClick={() => this.toggleModal('#modal-password', 'show')}>Изменить пароль</a></li>
                          <li><a href="/" onClick={this.loggout}>Выйти</a></li>
                        </ul>
                      </div>
                    ])}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </header>
          <Registration toggle={this.toggleModal} visible={this.state.showDone} />
          <Done toggle={this.toggleModal} visible={this.state.showDone}/>
          <Call toggle={this.toggleModal} />
          <Password toggle={this.toggleModal} />
          <Login handleInput={this.hendleInput} email={this.state.email} password={this.state.password} login={this.login} error={this.state.error}/>
        </Fragment>
      );
    } else {
      return <Preloader />;
    }
  }
}
