import React, {Fragment, useState} from 'react'
import axios from 'axios'

import Done from '../done'

const Call = props => {

  const [state, setState] = useState({name: '', phone: '', time: ''})

  const handleInput = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const submit = async e => {
    e.preventDefault()
    var date = new Date();

    var day = date.getDate(),
        month = date.getMonth(),
        year = date.getFullYear(),
        hours = date.getHours(),
        minutes = date.getMinutes();


    month++;
    if(day < 10) day = '0' + day
    if(month < 10) month = '0' + month
    if(hours < 10) hours = '0' + hours
    if(minutes < 10) minutes = '0' + minutes

    var time = day+'.'+month+'.'+year+' '+hours+':'+minutes;

    var newState = { ...state, time }

    await axios.post('/.netlify/functions/callCreate', newState).then(res => {
      props.toggle('#modal-call', 'hide');
      props.toggle('#modal-call-done', 'show');
      axios.post('/.netlify/functions/sendCall', newState)
      setTimeout(() => props.toggle('#modal-call-done', 'hide'), 4000);
    })
  }

  return(
    <Fragment>
      <div id="modal-call" className="modal uk-modal-full" uk-modal="">
        <div className="uk-modal-dialog uk-height-1-1 uk-flex uk-flex0-middle">
         	<button className="uk-modal-close-full uk-close-large" type="button" uk-close=""></button>
          <div className="uk-width-1-1">
           	<div className="uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
              <div className="reg_wrap uk-width-1-1 uk-flex uk-flex-center uk-flex-wrap uk-margin-large-top">

               	<form className="input_wrap" onSubmit={submit}>
  							 	<div className="uk-margin">
  					        <label className="uk-form-label" htmlFor="form-stacked-text">Ваше имя*</label>
  					        <div className="uk-form-controls">
  				            <input className='uk-input' name="name" type="text" placeholder="Иван Иванов" onChange={handleInput} tabIndex="1" value={state.name} />
  					        </div>
  						    </div>

                  <div className="uk-margin">
  					        <label className="uk-form-label" htmlFor="form-stacked-text">Ваш телефон*</label>
  					        <div className="uk-form-controls">
  				            <input className='uk-input' name="phone" type="text" tabIndex="1" onChange={handleInput} value={state.phone} />
  					        </div>
  						    </div>

  								<button className="uk-button uk-width-1-1 uk-button-default" tabIndex="13">Отправить</button>
  							</form>
              </div>
           	</div>
         	</div>
        </div>
      </div>
      <Done label="Ваша заявка принята. Ожидайте звонка в ближайшее время!" id="modal-call-done" />
    </Fragment>
  )
}


export default Call
