import React, {Fragment, useState} from 'react'
import axios from 'axios'
import Done from '../done'
import Cookies from 'js-cookie';

const Password = props => {

  const [state, setState] = useState({oldPassword: '', newPassword: '', controlPasswrod: '', error: false, email: ''})

  const handleInput = e => {
    setState({
      ...state,
      error: false,
      [e.target.name]: e.target.value
    })
  }

  const submit = e => {
    e.preventDefault()
    if(state.newPassword !== state.controlPasswrod){
      setState({
        ...state,
        error: 'notControl'
      })
    }else{
      var userData = JSON.parse(localStorage.getItem('user'))
      setState({
        ...state,
        email: userData.email
      })
      axios.post('/.netlify/functions/password', state).then(res => {
        props.toggle('#modal-password', 'hide');
        props.toggle('#modal-password-done', 'show');
        setTimeout(() => props.toggle('#modal-password-done', 'hide'), 2000);
      }).catch(err => {
        setState({
          ...state,
          error: 'notExist'
        })
      })
    }
  }

  return(
    <Fragment>
      <div id="modal-password" className="modal uk-modal-full" uk-modal="">
        <div className="uk-modal-dialog uk-height-1-1 uk-flex uk-flex-middle">
         	<button className="uk-modal-close-full uk-close-large" type="button" uk-close=""></button>
          <div className="uk-width-1-1">
           	<div className="uk-flex uk-flex-center uk-flex-middle">
              <div className="reg_wrap uk-width-1-1 uk-flex uk-flex-center uk-flex-wrap uk-margin-large-top">

                {state.error === 'notExist' ? <div className="uk-alert-danger" uk-alert="animation: true; duration: 200;">
                  <a className="uk-alert-close" nohref="" uk-close="">{null}</a>
                  <p>Введен неправильный пароль</p>
                </div> : ''}

                {state.error === 'notControl' ? <div className="uk-alert-danger" uk-alert="animation: true; duration: 200;">
                  <a className="uk-alert-close" nohref="" uk-close="">{null}</a>
                  <p>Новый пароль не совпадает!</p>
                </div> : ''}

               	<form className="input_wrap" onSubmit={submit}>
  							 	<div className="uk-margin">
  					        <label className="uk-form-label" htmlFor="form-stacked-text">Старый пароль*</label>
  					        <div className="uk-form-controls">
  				            <input className={state.error === 'notExist' ? 'uk-form-danger uk-input' : 'uk-input'} name="oldPassword" type="text" onChange={handleInput} tabIndex="1" value={state.oldPassword} />
  					        </div>
  						    </div>

                  <div className="uk-margin">
  					        <label className="uk-form-label" htmlFor="form-stacked-text">Новый пароль*</label>
  					        <div className="uk-form-controls">
  				            <input className={state.error === 'notControl' ? 'uk-form-danger uk-input' : 'uk-input'} name="newPassword" type="text" tabIndex="2" onChange={handleInput} value={state.newPassword} />
  					        </div>
  						    </div>

                  <div className="uk-margin">
  					        <label className="uk-form-label" htmlFor="form-stacked-text">Повторите пароль*</label>
  					        <div className="uk-form-controls">
  				            <input className={state.error === 'notControl' ? 'uk-form-danger uk-input' : 'uk-input'} name="controlPasswrod" type="text" tabIndex="3" onChange={handleInput} value={state.controlPasswrod} />
  					        </div>
  						    </div>

  								<button className="uk-button uk-width-1-1 uk-button-default" tabIndex="13">Отправить</button>
  							</form>
              </div>
           	</div>
         	</div>
        </div>
      </div>
      <Done label="Ваш пароль успешно изменен!" id="modal-password-done" />
    </Fragment>
  )
}


export default Password
